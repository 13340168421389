@import url(https://use.typekit.net/taf8eoc.css);
body {
  margin: 0;
  font-family: "forma-djr-deck"; }

* {
  letter-spacing: 0.5px; }

html {
  scroll-behavior: smooth; }

html, body {
  margin: 0;
  padding: 0;
  background-color: white; }

#root {
  overflow: hidden; }

#lz_overlay_chat, #lz_eye_catcher {
  right: auto !important;
  left: 0px;
  margin-left: 50px !important; }

.slick-dots {
  position: static;
  position: initial;
  bottom: auto; }
  .slick-dots li {
    margin: 0 5px; }
    .slick-dots li button {
      background: transparent; }
    .slick-dots li button:before {
      font-size: 15px;
      color: white;
      opacity: 1; }
    .slick-dots li.slick-active button:before {
      color: #4c4c4c;
      opacity: 1; }

.Maintenance_outer__1jmeO {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000; }
  .Maintenance_outer__1jmeO .Maintenance_background__1lrQn {
    background-color: white;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .Maintenance_outer__1jmeO .Maintenance_popup__1m7-4 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 600px;
    height: 400px;
    margin-left: -300px;
    margin-top: -200px;
    background-color: white;
    text-align: center;
    padding: 20px; }

