
@import url("https://use.typekit.net/taf8eoc.css");
body {
  margin: 0;
  font-family: "forma-djr-deck"
}


* {
  letter-spacing: 0.5px;
}
html {
  scroll-behavior: smooth;
}
html, body {
  margin: 0;
  padding: 0;
  background-color: white;
}

#root {
  overflow: hidden;
}

#lz_overlay_chat, #lz_eye_catcher {
  right: auto!important;
  left: 0px;
  margin-left: 50px!important;
}


.slick-dots {
  position: initial;
  bottom: auto;
  li {
    margin: 0 5px;
    button {
      background: transparent;
    }
    button:before {
      font-size: 15px;
      color: white;
      opacity: 1;
    }
    &.slick-active {
      button:before {
        color: #4c4c4c;
        opacity: 1;
      }
    }
  }
}
